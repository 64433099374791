import { IconName } from "@brite-inc/ui-components/dist/Icon";
import { SidebarStrings } from "../strings";
import { ROUTES_CONFIG_MAP } from "../routing";

export interface IMenuItem {
    iconName: IconName;
    link?: string;
    name: string;
    onClick?: () => void;
}

export function getConfig({ logout }: { logout: () => void }): IMenuItem[] {
    return [
        {
            iconName: "chart-bar",
            link: ROUTES_CONFIG_MAP.USER_ACTIVITY.path,
            name: SidebarStrings.UserActivity,
        },
        {
            iconName: "sign-out-alt",
            name: SidebarStrings.Logout,
            onClick: logout,
        },
    ];
}
