import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NotificationsContainer } from "@brite-inc/ui-hooks/dist/notifications";
import {
    RemoteCommand,
    RemoteCommandProvider,
} from "@brite-inc/ui-remote/dist";
import { UserSessionChecker } from "./modules/auth";
import { ROUTES_CONFIG_MAP, UnauthorizedRoutes } from "./modules/routing";
import { getAuthDomain, getReportDomain } from "./config/getApiDomains";
import { setupApp } from "./setupApp";

setupApp();

export function App() {
    const remoteCommand = new RemoteCommand(getAuthDomain(), getReportDomain());
    return (
        <RemoteCommandProvider remoteCommand={remoteCommand}>
            <Router>
                <NotificationsContainer />
                <UserSessionChecker />
                <Switch>
                    <Route
                        exact={ROUTES_CONFIG_MAP.LOGIN.exact}
                        path={ROUTES_CONFIG_MAP.LOGIN.path}
                        component={ROUTES_CONFIG_MAP.LOGIN.component}
                    />
                    <Route component={UnauthorizedRoutes} />
                </Switch>
            </Router>
        </RemoteCommandProvider>
    );
}
