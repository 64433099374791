import { IDetailedUserActivityResponseEntry } from "@brite-inc/ui-remote/dist";
import { Table } from "../../components/Table";

const tableStyles = {
    cells: {
        style: {
            cursor: "pointer",
        },
    },
};

interface IProps {
    date: string;
    entries: IDetailedUserActivityResponseEntry[];
    onRowClick: (customerId: string) => void;
}

export function CustomerSchoolTable(props: IProps) {
    const columns = [
        {
            grow: 2,
            name: "Customer",
            selector: "customerName",
            sortable: true,
        },
        {
            name: "School Type",
            selector: "schoolType",
            sortable: true,
        },
        {
            name: "Licenses",
            selector: "uniqueLicenses",
            sortable: true,
        },
        {
            name: "Test Licenses",
            selector: "testLicenses",
            sortable: true,
        },
        {
            name: "Chargeable Licenses",
            selector: (e: IDetailedUserActivityResponseEntry) =>
                Math.max(0, e.uniqueLicenses - e.testLicenses),
            sortable: true,
        },
        {
            name: "Lead",
            selector: "leadSource",
            sortable: true,
        },
    ];

    return (
        <Table<IDetailedUserActivityResponseEntry>
            columns={columns}
            entries={props.entries}
            defaultSortField={"customerName"}
            striped={true}
            title={`Report for ${props.date}`}
            customStyles={tableStyles}
            onRowClick={(entry: IDetailedUserActivityResponseEntry) =>
                props.onRowClick(entry.customerId)
            }
        />
    );
}
