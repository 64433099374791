import * as React from "react";
import { NotFoundStrings } from "../../modules/strings";

export function NotFoundPage() {
    React.useEffect(() => {
        document.title = NotFoundStrings.Title;
    }, []);

    return <div>not found</div>;
}
