import * as React from "react";
import { useHistory } from "react-router";
import { format, getYear, max, parse } from "date-fns";
import { useFetcher } from "@brite-inc/ui-hooks/dist/fetcher";
import { useNotification } from "@brite-inc/ui-hooks/dist/notifications";
import {
    useRemoteCommand,
    IUserActivityResponseDates,
    IUserActivityResponse,
} from "@brite-inc/ui-remote/dist";
import { UserActivityStrings } from "../../modules/strings";
import { Page } from "./components/Page";
import { Spinner } from "../../components/Spinner";
import { useUser } from "../../modules/auth";
import { ISelectedMonth } from "./types";
import { DATE_FORMAT } from "./constants";
import { ROUTES_CONFIG_MAP } from "../../modules/routing";

interface IState {
    customerId: string;
    dates: IUserActivityResponseDates;
    isLoading: boolean;
    lastUpdateDate: string;
    selectedMonth: ISelectedMonth | null;
}

export function UserActivityPage() {
    const [state, setState] = React.useState<IState>({
        customerId: "",
        dates: {},
        isLoading: true,
        lastUpdateDate: "",
        selectedMonth: null,
    });
    const { user } = useUser();
    const remoteCommand = useRemoteCommand();
    const notification = useNotification();
    const fetchApi = useFetcher();
    const history = useHistory();

    React.useEffect(() => {
        document.title = UserActivityStrings.PageTitle;
    }, []);

    React.useEffect(() => {
        fetchApi({
            onFinally: () => {
                setState((state) => ({
                    ...state,
                    isLoading: false,
                }));
            },
            onReject: (error) => {
                const message =
                    error.response?.status === 403
                        ? UserActivityStrings.ErrorUserNotAuthorized
                        : UserActivityStrings.ErrorRetrieveUserActivity;
                notification.error(message);
            },
            onResolve: (activity: IUserActivityResponse) => {
                removeEmptyCustomers(activity);
                const selectedMonth = getLatestMonth(activity.dates);
                if (selectedMonth) {
                    setState((state) => ({
                        ...state,
                        ...activity,
                        selectedMonth,
                    }));
                } else {
                    notification.error(
                        UserActivityStrings.NotEnoughDataToDisplayThatPage,
                    );
                }
            },
            promise: remoteCommand.userActivity(
                user!.customerId,
                getYear(new Date()),
                "CodaKid",
            ),
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleYearSelect = (year: number) => {
        setState((state) => ({ ...state, isLoading: true }));
        fetchApi({
            onFinally: () => {
                setState((state) => ({
                    ...state,
                    isLoading: false,
                }));
            },
            onResolve: (activity: IUserActivityResponse) => {
                removeEmptyCustomers(activity);
                const selectedMonth = getLatestMonth(activity.dates);
                if (selectedMonth) {
                    setState((state) => ({
                        ...state,
                        ...activity,
                        selectedMonth,
                    }));
                } else {
                    notification.error(
                        UserActivityStrings.NoDataForSelectedYear,
                    );
                }
            },
            promise: remoteCommand.userActivity(
                user!.customerId,
                year,
                "CodaKid",
            ),
        });
    };

    const handleMonthSelect = (date: string) => {
        history.push(
            ROUTES_CONFIG_MAP.DETAILED_USER_ACTIVITY.path
                .replace(":year", date.substr(0, 4))
                .replace(":month", date.substr(5, 2)),
        );
    };

    if (!state.selectedMonth && state.isLoading) {
        return <Spinner />;
    }

    if (!state.selectedMonth) {
        return null;
    }

    return (
        <div>
            <Page
                dates={state.dates}
                isLoading={state.isLoading}
                lastUpdateDate={state.lastUpdateDate}
                onMonthSelect={handleMonthSelect}
                onYearSelect={handleYearSelect}
                selectedMonth={state.selectedMonth}
            />
        </div>
    );
}

function removeEmptyCustomers(activity: IUserActivityResponse) {
    Object.values(activity.dates).forEach((v) => {
        const customers = v.customers;
        for (const customerKey of Object.keys(customers)) {
            if (customers[customerKey].uniqueUserLogins === 0) {
                delete customers[customerKey];
            }
        }
    });
}

function getLatestMonth(
    dates: IUserActivityResponseDates,
): ISelectedMonth | null {
    const formattedDates = Object.keys(dates);
    if (!formattedDates.length) {
        return null;
    }

    const currentDate = new Date();
    const maxDate = max(
        formattedDates.map((dateKey) =>
            parse(dateKey, DATE_FORMAT, currentDate),
        ),
    );
    const maxFormattedDate = format(maxDate, DATE_FORMAT);
    return getMonth(dates, maxFormattedDate);
}

function getMonth(
    dates: IUserActivityResponseDates,
    date: string,
): ISelectedMonth | null {
    if (!dates[date]) {
        return null;
    }

    return {
        ...dates[date],
        date,
    };
}
