import { NavLink } from "react-router-dom";
import { Icon } from "@brite-inc/ui-components/dist/Icon";
import { IMenuItem } from "../../config";
import styles from "./styles.module.scss";

interface IProps {
    item: IMenuItem;
    isActive: boolean;
}

export function MenuItem(props: IProps) {
    const { item } = props;
    const content = (
        <>
            <Icon name={item.iconName} className={styles.icon} />
            <div className={styles.name}>{item.name}</div>
        </>
    );

    if (item.onClick) {
        return (
            <div className={styles.wrapper} onClick={item.onClick}>
                {content}
            </div>
        );
    } else {
        return (
            <NavLink
                className={styles.wrapper}
                activeClassName={styles.wrapperActive}
                to={item.link!}
            >
                {content}
            </NavLink>
        );
    }
}
