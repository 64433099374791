import * as React from "react";
import { parse, format } from "date-fns";
import { BarExtendedDatum, ResponsiveBar } from "@nivo/bar";
import theme from "../../../../styles/theme.module.scss";
import { IChartDataItem, ISelectedMonth } from "../../types";
import { DATE_FORMAT } from "../../constants";
import { YearSelector } from "../YearSelector";
import styles from "./styles.module.scss";

interface IProps {
    data: IChartDataItem[];
    selectedMonth: ISelectedMonth;
    selectedYear: number;
    onMonthClick: (month: string) => void;
    onYearSelect: (year: number) => void;
}

const leftAxisFormatter = (value: string | number | Date): string | number => {
    const number = Number(value);

    if (isNaN(number)) {
        return "";
    }

    return Math.floor(number) === number ? number : "";
};

const bottomAxisFormatter = (
    dateObject: string | number | Date,
): string | number => {
    const date =
        typeof dateObject === "object"
            ? dateObject
            : parse(String(dateObject), DATE_FORMAT, new Date());

    return format(date, "MMMM");
};

const renderTooltip = (selection: BarExtendedDatum) => {
    const data = selection.data as unknown as IChartDataItem;
    const sortedCustomers = Array.from(data.customers).sort((a, b) =>
        a.customerName.localeCompare(b.customerName),
    );

    return (
        <div>
            {sortedCustomers.map((c, idx) => (
                <p key={idx}>
                    {c.customerName}: {c.uniqueLogins}
                </p>
            ))}
        </div>
    );
};

export function UniqueLoginsChart(props: IProps) {
    const determineBarColor = ({ data }: { data: IChartDataItem }) => {
        if (data.date === props.selectedMonth.date) {
            return theme.primaryBlue;
        } else {
            return theme.primaryPurple;
        }
    };

    return (
        <div className={styles.wrapper}>
            <YearSelector
                onYearSelect={props.onYearSelect}
                selectedYear={props.selectedYear}
            />

            <div className={styles.chartWrapper}>
                <ResponsiveBar
                    animate={true}
                    axisBottom={{
                        format: bottomAxisFormatter,
                        tickPadding: 15,
                        tickRotation: -45,
                        tickSize: 0,
                    }}
                    axisLeft={{
                        format: leftAxisFormatter,
                        tickPadding: 5,
                        tickRotation: 0,
                        tickSize: 0,
                    }}
                    borderColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                    }}
                    borderRadius={5}
                    colors={determineBarColor}
                    data={props.data}
                    enableLabel={true}
                    groupMode="stacked"
                    indexBy="date"
                    keys={["accumulatedUniqueLogins"]}
                    margin={{
                        bottom: 100,
                        left: 60,
                        right: 60,
                        top: 50,
                    }}
                    onClick={({ data }) => {
                        props.onMonthClick(
                            (data as unknown as IChartDataItem).date,
                        );
                    }}
                    // @ts-ignore
                    onMouseEnter={(data: IChartDataItem, event) => {
                        (event.target as SVGRectElement).style.cursor =
                            "pointer";
                    }}
                    // onMouseLeave={(_data: IChartDataItem, event) => {
                    //     event.target.style.cursor = "auto";
                    // }}
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fontSize: "16px",
                                },
                            },
                        },
                    }}
                    tooltip={renderTooltip}
                />
            </div>
        </div>
    );
}
