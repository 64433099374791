import { RouteProps } from "react-router";
import { LoginPage } from "../../pages/login";
import { NotFoundPage } from "../../pages/notFound";
import { UserActivityPage } from "../../pages/userActivity";
import { DetailedUserActivityPage } from "../../pages/detailedUserActivity";
import { DetailedSchoolActivityPage } from "../../pages/detailedSchoolActivity";

export enum RouteName {
    "DETAILED_USER_ACTIVITY" = "DETAILED_USER_ACTIVITY",
    "DETAILED_SCHOOL_ACTIVITY" = "DETAILED_SCHOOL_ACTIVITY",
    "LOGIN" = "LOGIN",
    "NOT_FOUND" = "NOT_FOUND",
    "USER_ACTIVITY" = "USER_ACTIVITY",
}

export type RouteConfig = {
    authRequired: boolean;
    component: RouteProps["component"];
    exact?: boolean;
    name: RouteName;
    path: string;
};

export const ROUTES_CONFIG_LIST: RouteConfig[] = [
    {
        authRequired: true,
        component: DetailedUserActivityPage,
        exact: true,
        name: RouteName.DETAILED_USER_ACTIVITY,
        path: "/user-activity/:year/:month",
    },
    {
        authRequired: true,
        component: DetailedSchoolActivityPage,
        exact: true,
        name: RouteName.DETAILED_SCHOOL_ACTIVITY,
        path: "/school-activity/:customerId/:year/:month",
    },
    {
        authRequired: false,
        component: LoginPage,
        exact: true,
        name: RouteName.LOGIN,
        path: "/",
    },
    {
        authRequired: true,
        component: NotFoundPage,
        exact: true,
        name: RouteName.NOT_FOUND,
        path: "/not-found",
    },
    {
        authRequired: true,
        component: UserActivityPage,
        exact: true,
        name: RouteName.USER_ACTIVITY,
        path: "/user-activity",
    },
];

export const ROUTES_CONFIG_MAP = ROUTES_CONFIG_LIST.reduce<any>(
    (map, routeConfig) => {
        return { ...map, [routeConfig.name]: routeConfig };
    },
    {},
) as { [key in RouteName]: RouteConfig };
