import * as React from "react";
import { getYear } from "date-fns";
import { Icon } from "@brite-inc/ui-components/dist/Icon";
import styles from "./styles.module.scss";

interface IProps {
    selectedYear: number;
    onYearSelect: (year: number) => void;
}

export function YearSelector(props: IProps) {
    const { onYearSelect, selectedYear } = props;

    const currentYear = React.useMemo(() => {
        return getYear(new Date());
    }, []);

    const hasNextYear = selectedYear < currentYear;

    return (
        <div className={styles.wrapper}>
            <button
                className={styles.prevYearButton}
                onClick={() => onYearSelect(selectedYear - 1)}
            >
                <Icon name="angle-left" />
            </button>
            <div className={styles.selectedYear}>{selectedYear}</div>
            {hasNextYear && (
                <button
                    className={styles.nextYearButton}
                    onClick={() => onYearSelect(selectedYear + 1)}
                >
                    <Icon name="angle-right" />
                </button>
            )}
        </div>
    );
}
