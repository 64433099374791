import { useHistory } from "react-router-dom";
import { useFetcher } from "@brite-inc/ui-hooks/dist/fetcher";
import { useRemoteCommand } from "@brite-inc/ui-remote/dist";
import { useUser } from "../../../auth";
import { ROUTES_CONFIG_MAP } from "../../../routing";
import { getConfig } from "../../config";
import { User } from "../User";
import { MenuItem } from "../MenuItem";
import styles from "./styles.module.scss";

export function Sidebar() {
    const { user, removeUser } = useUser();
    const history = useHistory();

    const remoteCommand = useRemoteCommand();
    const fetchApi = useFetcher();

    const handleLogoutClick = () => {
        fetchApi({ promise: remoteCommand.logout() });
        removeUser();
        history.push(ROUTES_CONFIG_MAP.LOGIN.path);
    };

    const menuItems = getConfig({ logout: handleLogoutClick });

    return (
        <div className={styles.wrapper}>
            {user && (
                <div className={styles.userWrapper}>
                    <User user={user} />
                </div>
            )}
            <div className={styles.menuWrapper}>
                {menuItems.map((item) => (
                    <div key={item.name} className={styles.menuItemWrapper}>
                        <MenuItem item={item} isActive={false} />
                    </div>
                ))}
            </div>
        </div>
    );
}
