import { LoginForm } from "../LoginForm";
import { LogoImage } from "../../../../components/LogoImage";
import styles from "./styles.module.scss";

interface IProps {
    onLogin: (login: string, password: string) => void;
    isSubmitting: boolean;
}

export function Page(props: IProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                <div className={styles.imageLogoWrapper}>
                    <LogoImage />
                </div>

                <div>
                    <LoginForm
                        onSubmit={props.onLogin}
                        isSubmitting={props.isSubmitting}
                    />
                </div>
            </div>
        </div>
    );
}
