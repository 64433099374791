import { getEnv } from "./getEnv";

export function getAuthDomain() {
    switch (getEnv()) {
        case "beta":
            return "https://api.codenow-beta.live/v2/";
        default:
            return "https://api.codenow.live/v2/";
    }
}

export function getReportDomain() {
    switch (getEnv()) {
        case "beta":
            return "https://report.api.codenow-beta.live/v1/";
        default:
            return "https://report.api.codenow.live/v1/";
    }
}
