import { Switch, Redirect } from "react-router-dom";
import { AuthorizedAppLayout } from "../../components/AuthorizedAppLayout";
import { ROUTES_CONFIG_LIST, ROUTES_CONFIG_MAP } from "./routes";
import { ProtectedRoute } from "./ProtectedRoute";
import { useUser } from "../auth";

export function UnauthorizedRoutes() {
    const { user } = useUser();
    const unauthorizedRoutes = ROUTES_CONFIG_LIST.filter((r) => r.authRequired);

    return (
        <AuthorizedAppLayout>
            <Switch>
                {unauthorizedRoutes.map((route) => (
                    <ProtectedRoute
                        component={route.component}
                        exact={route.exact}
                        isUserLoggedIn={!!user}
                        key={route.path}
                        path={route.path}
                        redirectPath={ROUTES_CONFIG_MAP.LOGIN.path}
                    />
                ))}
                <Redirect to={ROUTES_CONFIG_MAP.NOT_FOUND.path} />
            </Switch>
        </AuthorizedAppLayout>
    );
}
