import { IUser } from "./types";

const LocalStorageKey = "BRITE_REPORT_DATA_V2";

export function getUserFromLocalStorage(): IUser | null {
    const userJson = localStorage.getItem(LocalStorageKey);
    if (userJson) {
        return JSON.parse(userJson);
    } else {
        return null;
    }
}

export function removeUserFromLocalStorage(): void {
    localStorage.removeItem(LocalStorageKey);
}

export function setUserToLocalStorage(user: IUser): void {
    localStorage.setItem(LocalStorageKey, JSON.stringify(user));
}
