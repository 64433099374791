import DataTable, {
    IDataTableColumn,
    IDataTableStyles,
} from "react-data-table-component";
import styles from "./styles.module.scss";

interface IProps<T> {
    columns: IDataTableColumn<T>[];
    customStyles?: IDataTableStyles;
    defaultSortField?: string;
    entries: T[];
    onRowClick?: (row: T, e: MouseEvent) => void;
    striped?: boolean;
    title?: string;
}

export function Table<T>({
    columns,
    entries,
    customStyles,
    onRowClick,
    defaultSortField,
    striped,
    title,
}: IProps<T>) {
    return (
        <div className={styles.wrapper}>
            <DataTable
                columns={columns}
                data={entries}
                defaultSortField={defaultSortField}
                highlightOnHover={true}
                striped={striped}
                title={title}
                customStyles={customStyles}
                onRowClicked={onRowClick}
            />
        </div>
    );
}
