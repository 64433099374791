import * as React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useFetcher } from "@brite-inc/ui-hooks/dist/fetcher";
import { useNotification } from "@brite-inc/ui-hooks/dist/notifications";
import {
    useRemoteCommand,
    IDetailedUserActivityResponse,
    IDetailedUserActivityResponseEntry,
} from "@brite-inc/ui-remote/dist";
import {
    DetailedUserActivityStrings,
    UserActivityStrings,
} from "../../modules/strings";
import { useUser } from "../../modules/auth";
import { ROUTES_CONFIG_MAP } from "../../modules/routing";
import { Spinner } from "../../components/Spinner";
import { CustomerSchoolTable } from "./customerSchoolTable";

interface IState {
    customerId: string;
    entries: IDetailedUserActivityResponseEntry[];
    isLoading: boolean;
    lastUpdateDate: string;
}

export function DetailedUserActivityPage() {
    const [state, setState] = React.useState<IState>({
        customerId: "",
        entries: [],
        isLoading: true,
        lastUpdateDate: "",
    });
    const { user } = useUser();
    const remoteCommand = useRemoteCommand();
    const notification = useNotification();
    const fetchApi = useFetcher();
    const history = useHistory();
    const { month, year } = useParams<{
        month: string;
        year: string;
    }>();

    React.useEffect(() => {
        document.title =
            DetailedUserActivityStrings.PageTitle + ` - ${year}-${month}`;
    }, [month, year]);

    const handleSchoolSelect = (customerId: string) => {
        history.push(
            ROUTES_CONFIG_MAP.DETAILED_SCHOOL_ACTIVITY.path
                .replace(":customerId", customerId)
                .replace(":year", year)
                .replace(":month", month),
        );
    };

    React.useEffect(() => {
        fetchApi({
            onFinally: () => {
                setState((state) => ({
                    ...state,
                    isLoading: false,
                }));
            },
            onResolve: (activity: IDetailedUserActivityResponse) => {
                if (activity.entries.length > 0) {
                    setState((state) => ({
                        ...state,
                        entries: activity.entries.filter(
                            (e) => e.schoolType !== "Test",
                        ),
                        lastUpdateDate: activity.lastUpdatedDate,
                    }));
                } else {
                    notification.error(
                        UserActivityStrings.NotEnoughDataToDisplayThatPage,
                    );
                }
            },
            promise: remoteCommand.detailedUserActivity(
                user!.customerId,
                year,
                month,
                "CodaKid",
            ),
        });
    }, [month, year]); // eslint-disable-line react-hooks/exhaustive-deps

    if (state.isLoading) {
        return <Spinner />;
    }

    return (
        <CustomerSchoolTable
            date={`${year}-${month}`}
            entries={state.entries}
            onRowClick={handleSchoolSelect}
        />
    );
}
