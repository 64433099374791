import { IUser } from "../../../auth";
import styles from "./styles.module.scss";

interface IProps {
    user: IUser;
}

export function User({ user }: IProps) {
    return (
        <div className={styles.wrapper}>
            <img src={user.avatar} className={styles.avatar} alt="avatar" />
            <div className={styles.name}>{user.name}</div>
            <div>({user.schoolName})</div>
        </div>
    );
}
