import { IUser } from "./types";
import {
    getUserFromLocalStorage,
    removeUserFromLocalStorage,
    setUserToLocalStorage,
} from "./utils";

export function useUser(): {
    user: IUser | null;
    setUser: (user: IUser) => void;
    removeUser: () => void;
} {
    return {
        removeUser: removeUserFromLocalStorage,
        setUser: setUserToLocalStorage,
        user: getUserFromLocalStorage(),
    };
}
