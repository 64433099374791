import * as React from "react";
import {
    eachMonthOfInterval,
    endOfYear,
    format,
    getYear,
    parse,
    startOfYear,
} from "date-fns";
import {
    IUserActivityResponseDates,
    IUserActivityResponseCustomers,
} from "@brite-inc/ui-remote/dist";
import { IChartDataItem, ISelectedMonth } from "../../types";
import { DATE_FORMAT } from "../../constants";
import { UniqueLoginsChart } from "../UniqueLoginsChart";
import { MonthStats } from "../MonthStats";
import { Spinner } from "../../../../components/Spinner";
import styles from "./styles.module.scss";

interface IProps {
    dates: IUserActivityResponseDates;
    isLoading: boolean;
    lastUpdateDate: string;
    onMonthSelect: (month: string) => void;
    onYearSelect: (year: number) => void;
    selectedMonth: ISelectedMonth;
}

export function Page(props: IProps) {
    const { selectedMonth } = props;
    const selectedYear = React.useMemo(
        () => getYearFromDate(selectedMonth.date),
        [selectedMonth.date],
    );
    const chartData = React.useMemo(
        () => transformDatesToChartData(props.dates, selectedYear),
        [JSON.stringify(props.dates), selectedYear], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <div className={styles.wrapper}>
            {props.isLoading && <Spinner />}
            <MonthStats
                lastUpdateDate={props.lastUpdateDate}
                month={selectedMonth}
            />
            <UniqueLoginsChart
                data={chartData}
                onMonthClick={props.onMonthSelect}
                onYearSelect={props.onYearSelect}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
            />
        </div>
    );
}

function transformDatesToChartData(
    dates: IUserActivityResponseDates,
    year: number,
): IChartDataItem[] {
    const yearDate = parse(String(year), "yyyy", new Date());
    return eachMonthOfInterval({
        end: endOfYear(yearDate),
        start: startOfYear(yearDate),
    }).map((monthDate) => {
        const monthDateString = format(monthDate, DATE_FORMAT);
        const customers: IUserActivityResponseCustomers =
            Object.prototype.hasOwnProperty.call(dates, monthDateString)
                ? dates[monthDateString].customers
                : {};

        return {
            accumulatedUniqueLogins: Object.values(customers).reduce(
                (sum, c) => sum + (c.uniqueUserLogins - c.testLicenses),
                0,
            ),
            customers: Object.entries(customers).map(
                ([customerName, customer]) => ({
                    customerName,
                    uniqueLogins:
                        customer.uniqueUserLogins - customer.testLicenses,
                }),
            ),
            date: monthDateString,
        };
    });
}

function getYearFromDate(dateString: string): number {
    const date = parse(dateString, DATE_FORMAT, new Date());
    return getYear(date);
}
