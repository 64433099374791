import {
    faAngleLeft,
    faAngleRight,
    faChartBar,
    faCheckSquare,
    faCircleNotch,
    faCoffee,
    faDesktop,
    faFile,
    faFlask,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

export const ICONS = [
    faAngleLeft,
    faAngleRight,
    faChartBar,
    faCheckSquare,
    faCircleNotch,
    faCoffee,
    faDesktop,
    faFile,
    faFlask,
    faSignOutAlt,
];
