import * as React from "react";
import { Icon } from "@brite-inc/ui-components/dist/Icon";
import styles from "./styles.module.scss";

export function Spinner() {
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    React.useLayoutEffect(() => {
        if (wrapperRef.current) {
            setWidth(wrapperRef.current.offsetWidth);
            setHeight(wrapperRef.current.offsetHeight);
        }
    }, []);

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <div
                className={styles.innerWrapper}
                style={{
                    height: height + "px",
                    width: width + "px",
                }}
            >
                <Icon name="circle-notch" className={styles.spinner} />
            </div>

            <span role="status" className={styles.visuallyHidden}>
                Loading...
            </span>
        </div>
    );
}
