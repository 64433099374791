import { IDetailedSchoolActivityResponseEntry } from "@brite-inc/ui-remote/dist";
import { Table } from "../../components/Table";

interface IProps {
    date: string;
    entries: IDetailedSchoolActivityResponseEntry[];
}

export function SchoolTable(props: IProps) {
    const columns = [
        {
            grow: 2,
            name: "School Name",
            selector: "schoolName",
            sortable: true,
        },
        {
            name: "School Type",
            selector: "schoolType",
            sortable: true,
        },
        {
            name: "Licenses",
            selector: "uniqueLicenses",
            sortable: true,
        },
        {
            name: "Test Licenses",
            selector: "testLicenses",
            sortable: true,
        },
        {
            name: "Chargeable Licenses",
            selector: (e: IDetailedSchoolActivityResponseEntry) =>
                Math.max(0, e.uniqueLicenses - e.testLicenses),
            sortable: true,
        },
    ];

    return (
        <Table<IDetailedSchoolActivityResponseEntry>
            columns={columns}
            entries={props.entries}
            defaultSortField={"schoolName"}
            striped={true}
            title={`Report for ${props.date}`}
        />
    );
}
