import * as React from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";

interface IProps extends RouteProps {
    isUserLoggedIn: boolean;
    redirectPath: string;
}

export function ProtectedRoute(props: IProps) {
    const { component, isUserLoggedIn, redirectPath } = props;

    if (!component) {
        return null;
    }

    return (
        <Route
            path={props.path}
            render={(props) => {
                if (isUserLoggedIn) {
                    return React.createElement(component, { ...props });
                } else {
                    return <Redirect to={redirectPath} />;
                }
            }}
        />
    );
}
