import styles from "./styles.module.scss";

export function LogoImage() {
    return (
        <img
            src="https://brite-aws-prod-assets.s3-us-west-2.amazonaws.com/themes/default/logo.png"
            className={styles.image}
            alt="logo"
        />
    );
}
