import * as React from "react";
import { LoginStrings } from "../../../../modules/strings";
import styles from "./styles.module.scss";

interface IProps {
    isSubmitting: boolean;
    onSubmit: (login: string, password: string) => void;
}

export function LoginForm(props: IProps) {
    const [login, setLogin] = React.useState("");
    const [loginError, setLoginError] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!login) {
            setLoginError(LoginStrings.LoginRequired);
        }
        if (!password) {
            setPasswordError(LoginStrings.PasswordRequired);
        }
        if (!password || !login) {
            return;
        }

        props.onSubmit(login, password);
        setLoginError("");
        setPasswordError("");
    };

    return (
        <form className={styles.wrapper} onSubmit={handleFormSubmit}>
            <div className={styles.loginWrapper}>
                <input
                    className={styles.loginInput}
                    type="text"
                    placeholder={LoginStrings.LoginInputPlaceholder}
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                />

                {loginError && (
                    <div className={styles.validationError}>{loginError}</div>
                )}
            </div>

            <div className={styles.passwordWrapper}>
                <input
                    className={styles.passwordInput}
                    type="password"
                    placeholder={LoginStrings.PasswordInputPlaceholder}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                {passwordError && (
                    <div className={styles.validationError}>
                        {passwordError}
                    </div>
                )}
            </div>

            <button
                className={styles.loginButton}
                disabled={props.isSubmitting}
            >
                {LoginStrings.LoginButton}
            </button>
        </form>
    );
}
