import * as React from "react";
import queryString from "query-string";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { useFetcher } from "@brite-inc/ui-hooks/dist/fetcher";
import { useNotification } from "@brite-inc/ui-hooks/dist/notifications";
import { useRemoteCommand } from "@brite-inc/ui-remote/dist";
import { Page } from "./components/Page";
import { useUser } from "../../modules/auth";
import { ROUTES_CONFIG_MAP } from "../../modules/routing";
import { LoginStrings } from "../../modules/strings";

export function LoginPage() {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const { user, setUser, removeUser } = useUser();
    const location = useLocation();
    const history = useHistory();
    const remoteCommand = useRemoteCommand();
    const fetchApi = useFetcher();
    const notification = useNotification();

    React.useEffect(() => {
        document.title = LoginStrings.Title;
    }, []);

    React.useEffect(() => {
        const query = queryString.parse(location.search);
        if ("sessionExpired" in query) {
            removeUser();
            notification.error(LoginStrings.SessionExpired);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (user) {
        return <Redirect to={ROUTES_CONFIG_MAP.USER_ACTIVITY.path} />;
    }

    const handleLogin = (login: string, password: string) => {
        setIsSubmitting(true);
        fetchApi({
            onFinally: () => {
                setIsSubmitting(false);
            },
            onResolve: (data) => {
                if (data.login) {
                    const { login, ...user } = data;
                    setUser(user);
                    history.push(ROUTES_CONFIG_MAP.USER_ACTIVITY.path);
                } else {
                    notification.error(LoginStrings.InvalidCredentials);
                }
            },
            promise: remoteCommand.login(login, password),
        });
    };

    return <Page onLogin={handleLogin} isSubmitting={isSubmitting} />;
}
