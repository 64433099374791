import * as React from "react";
import { format, parseISO } from "date-fns";
import styles from "./styles.module.scss";
import { ISelectedMonth } from "../../types";
import { UserActivityStrings } from "../../../../modules/strings";

interface IProps {
    lastUpdateDate: string;
    month: ISelectedMonth;
}

export function MonthStats(props: IProps) {
    const { lastUpdateDate } = props;
    const lastUpdate = React.useMemo(() => {
        const date = parseISO(lastUpdateDate);
        return format(date, "MMMM-dd-yyyy");
    }, [lastUpdateDate]);

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>{UserActivityStrings.ReportTitle}</h1>
            <div>
                <p>{UserActivityStrings.ReportDescriptionLine1}</p>
                <p>{UserActivityStrings.ReportDescriptionLine2}</p>
                <p>{UserActivityStrings.ReportDescriptionLine3}</p>
            </div>
            <div className={styles.lastUpdate}>
                {UserActivityStrings.LastUpdateOn}: {lastUpdate}.
            </div>
        </div>
    );
}
