import * as React from "react";
import { useParams } from "react-router";
import { useFetcher } from "@brite-inc/ui-hooks/dist/fetcher";
import { useNotification } from "@brite-inc/ui-hooks/dist/notifications";
import {
    useRemoteCommand,
    IDetailedSchoolActivityResponse,
    IDetailedSchoolActivityResponseEntry,
} from "@brite-inc/ui-remote/dist";
import { DetailedSchoolActivityStrings } from "../../modules/strings";
import { Spinner } from "../../components/Spinner";
import { SchoolTable } from "./schoolTable";

interface IState {
    entries: IDetailedSchoolActivityResponseEntry[];
    isLoading: boolean;
    lastUpdateDate: string;
}

export function DetailedSchoolActivityPage() {
    const [state, setState] = React.useState<IState>({
        entries: [],
        isLoading: true,
        lastUpdateDate: "",
    });
    const remoteCommand = useRemoteCommand();
    const notification = useNotification();
    const fetchApi = useFetcher();
    const { customerId, month, year } = useParams<{
        customerId: string;
        month: string;
        year: string;
    }>();

    React.useEffect(() => {
        document.title =
            DetailedSchoolActivityStrings.PageTitle + ` - ${year}-${month}`;
    }, [month, year]);

    React.useEffect(() => {
        fetchApi({
            onFinally: () => {
                setState((state) => ({
                    ...state,
                    isLoading: false,
                }));
            },
            onReject: () => {
                notification.error(
                    DetailedSchoolActivityStrings.ErrorRetrieveSchoolActivity,
                );
            },
            onResolve: (activity: IDetailedSchoolActivityResponse) => {
                if (activity.entries.length > 0) {
                    setState((state) => ({
                        ...state,
                        entries: activity.entries.filter(
                            (e) => e.schoolType !== "Test",
                        ),
                        lastUpdateDate: activity.lastUpdatedDate,
                    }));
                } else {
                    notification.error(
                        DetailedSchoolActivityStrings.NotEnoughDataToDisplayThatPage,
                    );
                }
            },
            promise: remoteCommand.detailedSchoolActivity(
                customerId,
                year,
                month,
                "CodaKid",
            ),
        });
    }, [month, year]); // eslint-disable-line react-hooks/exhaustive-deps

    if (state.isLoading) {
        return <Spinner />;
    }

    return <SchoolTable date={`${year}-${month}`} entries={state.entries} />;
}
