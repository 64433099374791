import * as React from "react";
import { Sidebar } from "../../modules/sidebar";
import styles from "./styles.module.scss";

interface IProps {
    children: React.ReactNode;
}

export function AuthorizedAppLayout({ children }: IProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.sidebarWrapper}>
                <Sidebar />
            </div>
            <div className={styles.contentWrapper}>{children}</div>
        </div>
    );
}
