export enum LoginStrings {
    Title = "Login",
    SessionExpired = "User session has expired, please login again",
    InvalidCredentials = "Check your login and/or password",
    LoginRequired = "Please, enter a login.",
    PasswordRequired = "Please, enter a password.",
    LoginInputPlaceholder = "Login",
    PasswordInputPlaceholder = "Password",
    LoginButton = "Login",
}

export enum SidebarStrings {
    UserActivity = "User Activity",
    Logout = "Logout",
}

export enum NotFoundStrings {
    Title = "Not found",
}

export enum DetailedUserActivityStrings {
    LastUpdateOn = "Data last updated on",
    PageTitle = "Detailed user activity",
}

export enum UserActivityStrings {
    LastUpdateOn = "Data last updated on",
    NoDataForSelectedYear = "No data for selected year",
    NotEnoughDataToDisplayThatPage = "Not enough data to display that page",
    PageTitle = "User activity",
    ReportDescriptionLine1 = "A record is kept for every time a student accesses any content.",
    ReportDescriptionLine2 = "Each individual student access is counted once per month.",
    ReportDescriptionLine3 = "Below is the cumulative count of unique student access to content across all existing schools.",
    ReportTitle = "Unique User Access - CodaKid",
    ErrorRetrieveUserActivity = "Error retrieving user activity",
    ErrorUserNotAuthorized = "User is not authorized to access data",
}

export enum DetailedSchoolActivityStrings {
    LastUpdateOn = "Data last updated on",
    PageTitle = "Detailed school activity",
    NotEnoughDataToDisplayThatPage = "Not enough data to display that page",
    ErrorRetrieveSchoolActivity = "Error retrieving school activity",
}
