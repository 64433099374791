import { useEffect } from "react";
import { useFetcher } from "@brite-inc/ui-hooks/dist/fetcher";
import { useRemoteCommand } from "@brite-inc/ui-remote/dist";

export function UserSessionChecker() {
    const remoteCommand = useRemoteCommand();
    const fetchApi = useFetcher();

    useEffect(() => {
        fetchApi({ promise: remoteCommand.whoAmI() });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
}
